<template>
    <div class="rulelist">
        <el-card>
            <template #header>
                <span>充值规则</span>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-select v-model="merchantid" size="small" style="margin-right:20px;width: 100px;" @change="GetStore" clearable v-if="ismerchant != 1">
                        <el-option v-for="item in merchantlist" :key="item" :value="item.ID" :label="item.Merchant"></el-option>
                    </el-select>
                    <el-select v-model="storeid" size="small" style="margin-right:20px;width: 100px;" multiple :disabled='(merchantid || mid)?false:true'>
                        <el-option v-for="item in stores" :key="item" :value="item.ID" :label="item.Store"></el-option>
                    </el-select>
                    <el-select v-model="status" size="small" style="margin-right:20px;width: 100px;">
                        <el-option value="0" label="全部"></el-option>
                        <el-option value="1" label="启用"></el-option>
                        <el-option value="2" label="停用"></el-option>
                    </el-select>
                    <el-input v-model="keyword" size="small" style="margin-right:20px;width:250px"></el-input>
                    <el-button @click="init" size="small" type="primary">搜索</el-button>
                    <el-button @click="add" size="small" type="success">新增规则</el-button>
                </div>
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0;"
                        v-loading="load"
                        size="small"
                        element-loading-spinner="el-icon-loading" 
                        element-loading-text="加载中..."
                    >
                    <el-table-column label="编号" prop="UniqueID"></el-table-column>
                    <el-table-column label="商户名称" prop="Merchant"></el-table-column>
                    <el-table-column label="充值金额(元)" prop="Deposit">
                        <template #default='scope'>
                            <span>{{scope.row.Deposit/100}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="赠送(元)" prop="Bonus">
                        <template #default='scope'>
                            <span>{{scope.row.Bonus/100}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="到账(元)">
                        <template #default='scope'>
                            <span>{{(scope.row.Deposit/100 + scope.row.Bonus/100).toFixed(2)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态">
                        <template #default='scope'>
                            <span v-if="scope.row.Status == 1" style="color:#3CB371">启用</span>
                            <span v-if="scope.row.Status == 2" style="color:#FF0000">停用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="适用店铺" prop="StoreName">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template #default='scope'>
                            <el-button v-if="scope.row.Status == 1" @click="Disable(scope.row.ID)" type="text">停用</el-button>
                            <el-button v-if="scope.row.Status == 2" @click="Enable(scope.row.ID)" type="text">启用</el-button>
                            <el-button @click="Edit(scope.row.ID)" type="text">编辑</el-button>
                            <el-button @click="Log(scope.row.ID)" type="text">日志</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="addrule" title="新增充值规则" width="35%" center>
        <el-form :model="ruleinfo" label-width="150px" label-position="right" ref="ruleinfo" :rules="rule">
            <el-form-item label="请选择商户：" prop="MerchantID" v-if="ismerchant != 1">

                <el-select v-model="ruleinfo.MerchantID" size="small" @change="GetStore">
                    <el-option v-for="item in merchantlist" :key="item" :label="item.Merchant" :value="item.ID"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="充值金额：" prop="Deposit">
                <el-input-number v-model="ruleinfo.Deposit" size="small" :controls='false'></el-input-number>
            </el-form-item>
            <el-form-item label="赠送金额：" prop="Bonus">
                <el-input-number v-model="ruleinfo.Bonus" size="small" :controls='false'></el-input-number>
            </el-form-item>
            <el-form-item label="到账金额：">
                <span>{{(ruleinfo.Deposit*1 + ruleinfo.Bonus*1).toFixed(2)}} 元</span>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="适用店铺：" v-if="ruleinfo.MerchantID">
                <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox> -->
                <el-checkbox-group v-model="ruleinfo.selstores" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="item in stores" checked disabled :key="item.ID" :label="item.ID">{{item.Store}}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>
        <el-divider v-if="ruleinfo.MerchantID"></el-divider>
        <template #footer>
            <el-button @click="save" type="primary">确定</el-button>
            <el-button @click="cancel">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="rulelog" title="日志">
        <el-table size="small" :data="logdata" border style="width: 100%; margin: 1em 0;">
            <el-table-column label="操作时间">
                <template #default='scope'>
                    {{formatDate(scope.row.AddDTime)}}
                </template>
            </el-table-column>
            <el-table-column label="操作人" prop="OpName"></el-table-column>
            <el-table-column label="备注" prop="Remark"></el-table-column>
        </el-table>
    </el-dialog>
</template>
<script>    
import Cookies from 'js-cookie'
import qs from "qs"
import constant from "@/constant"
export default {
    data(){
        return {
            rulelog:false,
            logdata:[],
            load:false,
            merchantid:'',
            status:'',
            storeid:[],
            keyword:'',
            tabledata:[],
            stores:[],
            checkAll:false,
            addrule: false,
            isIndeterminate: true,
            totalcount:'',
            curpage:1,
            pagesize:10,
            ruleinfo:{
                ID:'',
                MerchantID:'',
                Deposit:0,
                Bonus:0,
                selstores:[],
            },
            rule:{
                MerchantID:[
                    {
                        required:true,
                        message:'请选择商户',
                        trigger:'blur'
                    }
                ],
                Deposit:[
                    {
                        required:true,
                        message:'请填写充值金额',
                        trigger:'blur'
                    }
                ],
                Bonus:[
                    {
                        required:true,
                        message:'请填写赠送金额',
                        trigger:'blur'
                    }
                ]
            }
        }
    },
    methods:{
        formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2); 
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
        Log(id){
            this.axios.get(constant.rulelog,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.rulelog = true,
                this.logdata = response.data;
            });
        },
        Enable(id){
            this.axios.get(constant.enablerule,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id,
                    opid:this.opid,
                    role:this.role,
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == 'OK'){
                    this.$message.success("操作成功");
                    this.$router.go(0);
                }else{
                    this.$message.error("操作成功");
                }
            });
        },
        Disable(id){
            this.axios.get(constant.disablerule,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id,
                    opid:this.opid,
                    role:this.role,
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == 'OK'){
                    this.$message.success("操作成功");
                    this.$router.go(0);
                }else{
                    this.$message.error("操作成功");
                }
            });
        },
        Edit(id){
            this.axios.get(constant.getrule,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.ruleinfo.ID = response.data.ID;
                this.ruleinfo.MerchantID = response.data.MerchantID;
                this.GetStore(this.ruleinfo.MerchantID);
                this.ruleinfo.Deposit = response.data.Deposit/100;
                this.ruleinfo.Bonus = response.data.Bonus/100;
                this.ruleinfo.selstores = response.data.selstores;
                this.addrule = true;
            });
        },
        handleCheckedCitiesChange(val){
            let checkedCount = val.length;
            this.checkAll = checkedCount === this.stores.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.stores.length;
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        handleCheckAllChange(val){
            console.log(val);
            console.log(this.stores);
            if(val){
                for(let i = 0;i<this.stores.length;i++){
                    this.ruleinfo.selstores.push(this.stores[i].ID);
                    console.log(this.ruleinfo.selstores);
                }
            }else{
                this.ruleinfo.selstores = [];
            }
            this.isIndeterminate = false;
        },
        add(){

            this.ruleinfo = {
                ID:'',
                MerchantID:'',
                Deposit:0,
                Bonus:0,
                selstores:[],
            }
            if(this.ismerchant == 1){
                this.ruleinfo.MerchantID = this.mid;
                this.GetStore(this.ruleinfo.MerchantID);
            }
                


            this.addrule = true;
        },
        cancel(){
            this.addrule = false;
        },
        save(){
            this.$refs['ruleinfo'].validate((valid)=>{
                if(valid){
                    console.log(this.ruleinfo);
                    if(this.ruleinfo.Deposit*1 <= 0 || this.ruleinfo.Bonus*1 <= 0){
                        this.$message.error("充值金额或者赠送金额不能为0");
                        return false;
                    }
                    this.axios.post(constant.saverule,qs.stringify(this.ruleinfo),{
                        headers:{
                            "content-type":"application/x-www-form-urlencoded"
                        },
                        params:{
                            opid:this.opid,
                            role:this.role,
                        }
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == 'OK'){
                            this.$message.success("操作成功");
                            this.$router.go(0);
                        }else{
                            this.$message.error("操作失败");
                        }
                    });
                }else{
                    return false;
                }
            });
           
        },
        GetStore(merchantid){
            console.log(merchantid);
            console.log("触发");
            this.axios.get(constant.allstore,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    merchantid:merchantid
                }
            }).then((response)=>{
                console.log(response.data);
                this.stores = response.data;
            });
        },
        init(){
            this.axios.get(constant.rulelist,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    storeid:JSON.stringify(this.storeid),
                    merchantid:this.merchantid,
                    keyword:this.keyword,
                    status:this.status,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        },

    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.opid = Cookies.get("id");
        this.role = Cookies.get("role");
        this.init();
        this.axios.get(constant.allmerchant,{
            headers:{
                "content-type":"application/json"
            }
        }).then((response)=>{
            console.log(response.data);
            this.merchantlist = response.data;
        });
    }
}
</script>
<style scoped>
.m {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>